import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import loadable from '@loadable/component'

const CardList = loadable(() => import('/src/components/PageBuilder/layouts/standard/CardList'))

const HelpCategories = ({ backgroundColour }) => {
    const categoriesQuery = useStaticQuery(
        graphql`
            query {
                categories: allWpHelpCategory {
                    nodes {
                        id
                        count
                        name
                        link
                        category {
                            featuredImage {
                                id
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(width: 35)
                                    }
                                }
                                altText
                                title
                            }
                        }
                    }
                }
            }
        `
    )

    const itemsRepeater = categoriesQuery.categories.nodes

    itemsRepeater.forEach((node) => {
        node.image = node.category.featuredImage
        node.title = node.name
        node.callToAction = {
            url: node.link
        }
    })

    return (
        <CardList
            type={'search'}
            backgroundColour={backgroundColour}
            titleArea={{
                addTitleArea: true,
                titlePosition: 'centered',
                title: 'Explore Help & Support Articles by Category',
                description: `<p>We're here to help you with all Protect Line products and Life Insurance queries. Find our frequently asked questions, trending topics, support options and more...<p>`
            }}
            columns={'3'}
            cardOrientation={'vertical'}
            addImages={true}
            addTitles={true}
            addCtas={true}
            ctaType={'card'}
            itemsRepeater={itemsRepeater}
        />
    )
}

export default HelpCategories
